import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import {Router} from '@angular/router';

import { User } from '../models/user';
import { Observable, of, from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private auth:AngularFireAuth, 
        private db:AngularFirestore, 
        private router:Router) 
    { }

    logout() {
        this.auth.signOut();
        this.router.navigate(['/auth/login']);
    }

    createUser(user:User):Observable<any> {
        return from(
            this.db.collection('users').doc(user.uid).set({
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                phoneNumber: (user.phoneNumber) ? user.phoneNumber : null,
                provider: user.provider
            })
        )
    }
}
