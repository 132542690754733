// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAIlAfCcOYGshSVVr2shMYEIWCEEqu00QE",
    authDomain: "playlist-assistant-prod.firebaseapp.com",
    databaseURL: "https://playlist-assistant-prod.firebaseio.com",
    projectId: "playlist-assistant-prod",
    storageBucket: "playlist-assistant-prod.appspot.com",
    messagingSenderId: "513661920162",
    appId: "1:513661920162:web:5f4c5e31c2ff4e610808fb",
    measurementId: "G-C7NECWTMVK"
  },
  spotify: {
    tokenURL: "https://accounts.spotify.com/api/token",
    getArtistURL: "https://playlist-assistant-prod.firebaseapp.com/spotifyGetArtist?id=",
    getPlaylistURL: "https://playlist-assistant-prod.firebaseapp.com/spotifyGetPlaylist?id=",
    getUserURL: "https://playlist-assistant-prod.firebaseapp.com/spotifyGetUser?id="
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
